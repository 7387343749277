import {
  Component,
  OnInit,
  Inject,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { ModalBasicoComponent } from "../modal-basico/modal-basico.component";
import { User } from "src/app/models/user";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { UserService } from "src/app/services/user.service";
import { Router } from "@angular/router";
import { InstituicaoService } from "src/app/services/instituicao.service";
import { TipoInscricaoService } from "src/app/services/tipo-inscricao.service";
import { HttpClient } from "@angular/common/http";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { EventEmitter } from "@angular/core";
import util from "../../../util";
declare var paypal;
@Component({
  selector: "app-modal-historico",
  templateUrl: "./modal-historico.component.html",
  styleUrls: ["./modal-historico.component.scss"],
})
export class ModalHistoricoComponent implements OnInit {
  @ViewChild("paypal", { static: true }) paypalElement: ElementRef;
  visible: boolean = false;
  urlAPI = util.api; // api rest fake
  constructor(
    public dialogRef: MatDialogRef<ModalBasicoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private cd: ChangeDetectorRef,
    private httpClient: HttpClient,
    public dialog: MatDialog
  ) {}
  onAdd = new EventEmitter();
  emailFormControl = new FormControl("", [
    Validators.required,
    Validators.email,
  ]);
  senhaFormControl = new FormControl("", [Validators.required]);

  ngOnInit() {
    console.log(this.data);
    this.initConfig();
  }
  initConfig() {
    const self = this;
    paypal
      .Buttons({
        createOrder: (data, actions) => {
          // pass in any options from the v2 orders create call:
          // https://developer.paypal.com/api/orders/v2/#orders-create-request-body
          const createOrderPayload = {
            purchase_units: [
              {
                amount: {
                  currency_code: "BRL",
                  value: self.data.valor,
                },
              },
            ],
            intent: "CAPTURE",
          };

          return actions.order.create(createOrderPayload);
        },

        // finalize the transaction
        onApprove: (data, actions) => {
          const captureOrderHandler = (details) => {
            const payerName = details.payer.name.given_name;
            this.salvarPagamentoPaypal();
            console.log(payerName);
            console.log("Transaction completed!");
          };

          return actions.order.capture().then(captureOrderHandler);
        },

        // handle unrecoverable errors
        onError: (err) => {
          console.error(
            "An error prevented the buyer from checking out with PayPal"
          );
        },
      })
      .render(this.paypalElement.nativeElement);
  }
  salvarPagamentoPaypal() {
    let env = {
      usuarioId: this.data.id_usuario,
      valor: this.data.valor,
      tInscri: this.data.tipo_usuario,
      nome: this.data.nome,
      email: this.data.email,
      ano: this.data.ano,
    };
    this.httpClient
      .post(this.urlAPI + "pagar/paypal", env, { observe: "response" })
      .subscribe((r) => {
        this.visible = true;
        if (r.status === 200) {
          let url: any = r.body;
          let modal = this.dialog.open(ModalBasicoComponent, {
            width: "33vw",
            data: {
              mnsg: "Pagamento Realizado com succeso",
              buttonOK: true,
              buttonCancelar: false,
            },
          });
          modal.componentInstance.onAdd.subscribe(() => {
            this.dialogRef.close();
            this.visible = true;
            this.router.navigate(["/Main/Home"]);
          });
          modal.afterClosed().subscribe((result) => {
            this.dialogRef.close();
            this.visible = true;
            this.router.navigate(["/Main/Home"]);
          });
        }
        // console.log(r)
      });
  }
  onNoClick(): void {
    this.onAdd.emit();
    this.dialogRef.close();
  }
  cancelar() {
    this.dialogRef.close();
  }
  pagamentopagseguro() {
    this.visible = false;
    let env = {
      usuarioId: this.data.id_usuario,
      valor: this.data.valor,
      tInscri: this.data.tipo_usuario,
      nome: this.data.nome,
      email: this.data.email,
      ano: this.data.ano,
    };
    this.httpClient
      .post(this.urlAPI + "pagseguro/credencias", env, { observe: "response" })
      .subscribe((r) => {
        this.dialogRef.close();
        this.visible = true;
        if (r.status === 200) {
          let url: any = r.body;
          window.open(url);
          this.router.navigate(["/Main/Home"]);
        }
        // console.log(r)
      });
  }
}
