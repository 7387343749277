import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { User } from '../models/user';
import { Http } from '@angular/http';
import util from '../util';

@Injectable({
  providedIn: 'root'
})
export class TipoInscricaoService {
  urlAPI = util.api; // api rest fake
  
  constructor(
    private httpClient: HttpClient,
    ) { }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  }
  GetTipodeInscricao(){    
    let res = this.httpClient.get(this.urlAPI + 'TipodeInscricao/listar',{observe: 'response'})
    .toPromise().then((res)=>{
  
      return res
    }).catch((err)=>{
      return err
    })
    console.log(res)
        return res
  }
  ValorAssociacao(){
    let res = this.httpClient.get(this.urlAPI + 'tedass/listar',{observe: 'response'})
    .toPromise().then((res)=>{
      let ho =    {
        "id": 5,
        "valor": "0",
        "nome": "Sócio Honorário"
    }
    let se =    {
      "id": 6,
      "valor": "0",
      "nome": "Secretaria SBCAT"
  }
    let arrayres:any;
    arrayres = res;
    arrayres.body.push(ho)
    arrayres.body.push(se)
      return arrayres
    }).catch((err)=>{
      return err
    })

        return res
  }
  ValorAssociacaoano(ano){
    let res = this.httpClient.get<User>(this.urlAPI + 'valoreslistarAno/'+ano,{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  Regional(){
    let res = this.httpClient.get<User>(this.urlAPI + 'regionais/listar',{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }

  AreaAtuacao(){
    let res = this.httpClient.get<User>(this.urlAPI + 'areaatuacao/listar',{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }

  ValorInscricao(){
    let res = this.httpClient.get<User>(this.urlAPI + 'preco/listar',{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  Eventos(){
    let res = this.httpClient.get<User>(this.urlAPI + 'evento/pesquisarAberto',{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  AllEventos(){
    let res = this.httpClient.get<User>(this.urlAPI + 'evento/listar',{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  desconto(id){
    let res = this.httpClient.get<User>(this.urlAPI + 'evento/desconto/1/'+id,{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  inscreveEvento(form){
    let res = this.httpClient.post(this.urlAPI + 'inscricoes/cadastrar',form,{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}

