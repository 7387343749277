import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { User } from '../models/user';
import { Http } from '@angular/http';
import { SideNavComponent } from '../modules/layout/side-nav/side-nav.component';
import util from '../util';


@Injectable({
  providedIn: 'root'
})
export class HistoricoService {
  urlAPI = util.api; // api rest fake


  constructor(
    private httpClient: HttpClient,
    ) { }
    @Output()

    httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }


    getpendencia(id) {
      let res = this.httpClient.get(this.urlAPI + 'usuario/pesquisar/pendencias/'+id,{observe: 'response'})
       .toPromise().then((res)=>{
         return res
       }).catch((err)=>{
         return err
       })
       return res
     }
}
 