import { Component, OnInit, Inject } from "@angular/core";
import { ModalBasicoComponent } from "../modal-basico/modal-basico.component";
import { User } from "src/app/models/user";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { UserService } from "src/app/services/user.service";
import { Router } from "@angular/router";
import { InstituicaoService } from "src/app/services/instituicao.service";
import { TipoInscricaoService } from "src/app/services/tipo-inscricao.service";
import { HttpClient } from "@angular/common/http";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { EventEmitter } from "@angular/core";
import util from "../../../../app/util";

@Component({
  selector: "app-modal-usuario",
  templateUrl: "./modal-usuario.component.html",
  styleUrls: ["./modal-usuario.component.scss"],
})
export class ModalUsuarioComponent implements OnInit {
  user: User;
  foto: File;

  imagemperfil = util.storage + "/perfil/Silhueta-png-cinzento-300x284.png";
  form = {
    id: "",
    email: " ",
    cpf: " ",
    comprovante: null,
    senha: " ",
    autorizadoTipo: 1,
    telefone: " ",
    tipoUsuario: " ",
    universidade: {
      id: "",
    },
    nome: " ",
    password: "",
  };
  visible: boolean = true;
  perfilForm: FormGroup;
  nome = new FormControl("", [Validators.required]);
  email = new FormControl("", [Validators.required]);
  tipo = new FormControl("", [Validators.required]);
  instituicao: any;
  cpf = new FormControl("", [Validators.required]);
  instituicaoCon = new FormControl("", [Validators.required]);
  instituicaoCheia: any;
  tel = new FormControl("");
  senha = new FormControl("", [Validators.required]);
  valoresassocia: any;
  documento: File;
  constructor(
    public dialogRef: MatDialogRef<ModalBasicoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userservice: UserService,
    private formBuilder: FormBuilder,
    private router: Router,
    private instituicaoservi: InstituicaoService,
    private tipoInscricao: TipoInscricaoService,
    private httpClient: HttpClient,
    public dialog: MatDialog
  ) {}
  onAdd = new EventEmitter();
  emailFormControl = new FormControl("", [
    Validators.required,
    Validators.email,
  ]);
  senhaFormControl = new FormControl("", [Validators.required]);

  ngOnInit() {
    this.valorAssociacao();
    this.getinstituicao();
    this.documento = null;
    this.foto = null;

    this.userservice
      .getusuariosunico(this.data.id)
      .then((res) => {
        this.user = res.body;
        if (res.body.tipoUsuario == null) {
          res.body.tipoUsuario = 1;
          res.body.universidade = {
            id: 0,
          };
        }
        if (res.body.universidade == null) {
          res.body.universidade = {
            id: 0,
          };
        }
        this.form = res.body;

        if (this.user.foto) {
          this.imagemperfil = util.storage + this.user.foto;
        }

        this.visible = false;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  onNoClick(): void {
    this.onAdd.emit();
    this.dialogRef.close();
  }
  cancelar() {
    this.dialogRef.close();
  }
  getinstituicao() {
    this.instituicaoservi
      .getinstituicao()
      .then((ins) => {
        this.instituicao = Array.from(new Set(ins.body.map((s) => s.nome))).map(
          (nome) => {
            return {
              id: ins.body.find((s) => s.nome === nome).id,
              nome: nome,
              sigla: ins.body.find((s) => s.nome === nome).sigla,
            };
          }
        );
        this.instituicaoCheia = this.instituicao;
        this.instituicao;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  valorAssociacao() {
    this.tipoInscricao
      .ValorAssociacao()
      .then((res) => {
        this.valoresassocia = res.body;
      })
      .catch((err) => {});
  }

  salvar() {
    this.visible = true;
    let form: any;
    form = {
      id: this.form.id,
      email: this.form.email,
      cpf: this.form.cpf,
      telefone: this.form.telefone,
      atividade: this.form.tipoUsuario,
      universidade: this.instituicaoCon.value,
      nome: this.form.nome,
      senha: this.senha.value,
    };

    if (this.foto) {
      let imagem = this.foto;
      let fd = new FormData();
      fd.append("image", imagem, imagem.name);
      this.userservice.subirfotoPerfil(form.id, fd).then((res) => {
        this.foto = null;
      });
    }
    if (form.atividade == 1 || form.atividade == 5 || form.atividade == 6) {
      this.visible = true;
      form.autorizadoatividade = 2;
      this.userservice
        .alterarusuario(form.id, form)
        .then((res) => this.ngOnInit())
        .catch((e) => console.log(e));
    } else if (
      (this.documento != null && form.atividade != 1) ||
      (this.documento == null &&
        this.form.comprovante != null &&
        form.atividade != 1)
    ) {
      // re-envia documentos
      if (this.documento) {
        this.visible = true;
        form.autorizadoatividade = 1;
        let imagem = this.documento;
        let fd = new FormData();
        fd.append("image", imagem, imagem.name);
        this.userservice.subirDocument(form.id, fd).then((res) => {
          form.comprovante = res.comprovante;
          this.userservice
            .alterarusuario(form.id, form)
            .then((res) => this.ngOnInit())
            .catch((e) => console.log(e));
        });
      } else {
        form.comprovante = this.form.comprovante;

        this.userservice
          .alterarusuario(form.id, form)
          .then((res) => this.ngOnInit())
          .catch((e) => console.log(e));
      }
    } else {
      this.visible = true;
      form.autorizadoatividade = 1;
      this.userservice
        .alterarusuario(form.id, form)
        .then((res) => this.ngOnInit())
        .catch((e) => console.log(e));
    }
  }

  arquivo(event) {
    this.foto = <File>event.target.files[0];
    let tes: any;
    var reader = new FileReader();
    reader.readAsDataURL(this.foto);
    reader.onload = () => {
      tes = reader.result as string;
      this.imagemperfil = tes;
    };
  }
  comprovante(event) {
    this.documento = <File>event.target.files[0];
  }
  applyFilter($event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.instituicao = this.instituicaoCheia.filter(function (el) {
      return el.nome.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
    });
  }
}
