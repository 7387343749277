import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { User } from "../models/user";
import { Http } from "@angular/http";
import util from "../util";

@Injectable({
  providedIn: "root",
})
export class PagamentoService {
  urlAPI = util.api; // api rest fake
  constructor(private httpClient: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };
  pegarPagementos() {
    let env = this.httpClient
      .get<User[]>(this.urlAPI + "tedass/todo", { observe: "response" })
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return env;
  }
  pegarPagementosBoleto() {
    let env = this.httpClient
      .get<User[]>(this.urlAPI + "boleto/todo", { observe: "response" })
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return env;
  }

  pegarPagementosmeu(id) {
    let env = this.httpClient
      .get<User[]>(this.urlAPI + "tedass/doCara/" + id, { observe: "response" })
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return env;
  }

  pegarPagementosmeuBoleto(id) {
    let env = this.httpClient
      .get<User[]>(this.urlAPI + "boleto/doCara/" + id, { observe: "response" })
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return env;
  }
  pegarPagementosPAgseguro(id) {
    let env = this.httpClient
      .get(this.urlAPI + "pagseguro/acompanhar/" + id)
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return env;
  }
  pegarmeuPagementosPAgseguro(id) {
    let env = this.httpClient
      .get(this.urlAPI + "pagseguro/acompanharmeus/" + id)
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return env;
  }
  pegarPagementosPaypal(id) {
    let env = this.httpClient
      .get(this.urlAPI + "pegar/paypal/" + id)
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return env;
  }
  pegarPagementosPaypalinscricao(id) {
    let env = this.httpClient
      .get(this.urlAPI + "pegar/paypal/inscricao/" + id)
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return env;
  }
  recusarDoc(id, form) {
    let env = this.httpClient
      .post(this.urlAPI + "ted/tedReprovarAsso/" + id, form)
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return env;
  }
  recusarDocBoleto(id, form) {
    let env = this.httpClient
      .post(this.urlAPI + "boleto/boletoReprovarAsso/" + id, form)
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return env;
  }
  aprovadoDoc(id, form) {
    let env = this.httpClient
      .post(this.urlAPI + "ted/tedAprovarAsso/" + id, form)
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return env;
  }
  aprovadoDocBoleto(id, form) {
    let env = this.httpClient
      .post(this.urlAPI + "boleto/boletoAprovarAsso/" + id, form)
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return env;
  }
  ReceberTed(form) {
    let env = this.httpClient
      .post(this.urlAPI + "recebiTedass/cadastrar", form)
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return env;
  }
  ReceberBoleto(form) {
    let env = this.httpClient
      .post(this.urlAPI + "recebiTedass/cadastrar", form)
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return env;
  }

  private handleError(error: any): Promise<any> {
    console.error("An error occurred", error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
