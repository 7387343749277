import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { User } from '../models/user';
import { Http } from '@angular/http';
import { SideNavComponent } from '../modules/layout/side-nav/side-nav.component';
import util from '../util';

@Injectable({
  providedIn: 'root'
})
export class EventoService {
  urlAPI = util.api; // api rest fake


  constructor(
    private httpClient: HttpClient,
    ) { }
    @Output()

    httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
    getEventos() {
      let res = this.httpClient.get(this.urlAPI + 'evento/pesquisarAberto',{observe: 'response'})
       .toPromise().then((res)=>{
         return res
       }).catch((err)=>{
         return err
       })
       return res
     }
     getEventosDatafim() {
      let res = this.httpClient.get(this.urlAPI + 'evento/pesquisarDatafim',{observe: 'response'})
       .toPromise().then((res)=>{
         return res
       }).catch((err)=>{
         return err
       })
       return res
     }
     gerarRecibo(id) {
      let form = {id:id}
      let res = this.httpClient.get(this.urlAPI + 'inscricoes/recibo/'+id,{observe: 'response'})
       .toPromise().then((res)=>{
         return res
       }).catch((err)=>{
         return err
       })
       return res
     }
     updateEvento(form) {
      let res = this.httpClient.post(this.urlAPI + 'evento/alterar/'+form.id,form)
       .toPromise().then((res)=>{
         return res
       }).catch((err)=>{
         return err
       })
       return res
     }
     novoEvento(form) {
      let res = this.httpClient.post(this.urlAPI + 'evento/cadastrar',form)
       .toPromise().then((res)=>{
         return res
       }).catch((err)=>{
         return err
       })
       return res
     }
     desconto(form){
      let res = this.httpClient.post(this.urlAPI + 'evento/descontoAdmin',form)
      .toPromise().then((res)=>{
        return res
      }).catch((err)=>{
        return err
      })
      return res
     }
     getdescontoAdmin(consul){
      let res = this.httpClient.post(this.urlAPI + 'evento/consultaDesconto',consul,{observe: 'response'})
      .toPromise().then((res)=>{
        return res
      }).catch((err)=>{ 
        return err
      })
          return res
    }
}
