import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { ModalBasicoComponent } from "src/app/modules/modais/modal-basico/modal-basico.component";
import { ModalHistoricoComponent } from "src/app/modules/modais/modal-historico/modal-historico.component";
import { PagamentoService } from "src/app/services/pagamento.service";
import { TipoInscricaoService } from "src/app/services/tipo-inscricao.service";
import { HttpClient } from "@angular/common/http";
import { UserService } from "src/app/services/user.service";
import { HistoricoService } from "src/app/services/historico.service";
import { Router } from "@angular/router";
import * as papa from "papaparse"; 
import util from "../../../util";
import { ListaUsuarioComponent } from "src/app/modules/pages/pages/lista-usuario/lista-usuario.component";
import { MatTableDataSource } from "@angular/material/table";
import { UserData } from "../../pages/pages/acompanhar-pagamento/acompanhar-pagamento.component";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

@Component({
  selector: "app-modal-visualizacaohistorico",
  templateUrl: "./modal-visualizacaohistorico.component.html",
  styleUrls: ["./modal-visualizacaohistorico.component.scss"],
})
export class ModalVisualizacaohistoricoComponent implements OnInit {
  urlAPI = util.api; // api rest fake
  dataSource: MatTableDataSource<UserData>;
  user: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalBasicoComponent>,
    private pagamento: PagamentoService,
    private tipoInscricao: TipoInscricaoService,
    private httpClient: HttpClient,
    public dialog: MatDialog,
    private router: Router,
    private userservice: UserService,
    private historicoService: HistoricoService
  ) {}
  visible: boolean = true;
  tipousu: any;
  historico: any;
  modificar: boolean = false;
  ngOnInit() {
    const id = this.data.id;
    this.userservice
      .getusuariosunico(this.data.id)
      .then((r) => {
        this.user = r.body;
        this.visible = false;
      })
      .catch((e) => console.log(e));
    this.valorAssociacao();
    this.consultarpendencia(id);
  }

  valorAssociacao() {
    this.tipoInscricao
      .ValorAssociacao()
      .then((res) => {
        this.tipousu = res.body;
      })
      .catch((err) => {});
  }

  modalBasico(mnsg) {
    let modal = this.dialog.open(ModalBasicoComponent, {
      width: "33vw",
      data: { mnsg: mnsg, buttonOK: true, buttonCancelar: false },
    });
    modal.afterClosed().subscribe((result) => {
      this.visible = true;

      this.ngOnInit();
    });
  }
  isento(){
    let anoatual =
    new Date(new Date().setFullYear(new Date().getFullYear(), 0, 1))
      .toJSON()
      .split("T")[0].split('-')[0];
    let mnsg = 'O sócio possui as anuidades dos anos de '+(Number(anoatual) - 1)+' e '+anoatual+' em dia.'
    this.modalBasico(mnsg);
  }
  cancelar() {
    this.dialogRef.close();
  }
  consultarpendencia(id) {
    this.historicoService
      .getpendencia(id)
      .then((r) => {
        this.historico = r.body;
      })
      .catch((e) => {});
  }

  donwloadPdf(item) {
    let url =
    this.urlAPI+"Recibo/gerar/" +
      this.user.id +
      "/" +
      item.ano +
      "/" +
      item.valor;
    window.open(url);
    console.log(item);
  }
  soma(valor1, valor2) {
    return valor1 + valor2;
  }

  editarHistorico(item) {
    item.id_usuario = this.user.id;
    item.tipo_usuario = this.user.tipoUsuario;
    item.nome = this.user.nome;
    item.email = this.user.email;

    // console.log(item);
    let modal = this.dialog.open(ModalHistoricoComponent, {
      // width: "59vw",
      height: "auto",
      data: item,
    });
    modal.afterClosed().subscribe((result) => {
      this.visible = true;
      this.ngOnInit();
    });
  }
}
